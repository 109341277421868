var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_vm._m(0),_c('br'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-6 col-md-6 d-flex align-items-stretch flex-column"},[_c('div',{staticClass:"card bg-light d-flex flex-fill"},[_c('div',{staticClass:"card-header text-muted border-bottom-0"}),_vm._m(1),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"text-right"},[_c('router-link',{staticClass:"btn btn-sm btn-primary",attrs:{"to":{
                            name: 'Rapor Evaluasi',
                            params: { filter: { ujian_type: 'latihan'}},
                        }}},[_c('i',{staticClass:"fas fa-arrow-right"}),_vm._v(" Detail ")])],1)])])]),_c('div',{staticClass:"col-12 col-sm-6 col-md-6 d-flex align-items-stretch flex-column"},[_c('div',{staticClass:"card bg-light d-flex flex-fill"},[_c('div',{staticClass:"card-header text-muted border-bottom-0"}),_vm._m(2),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"text-right"},[_c('router-link',{staticClass:"btn btn-sm btn-primary",attrs:{"to":{
                            name: 'Tipe Rapor Evaluasi',
                            params: { filter: { ujian_type: 'ujian'}},
                        }}},[_c('i',{staticClass:"fas fa-arrow-right"}),_vm._v(" Detail ")])],1)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h4',[_vm._v("Pilih salah satu")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body pt-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h2',{staticClass:"lead"},[_c('b',[_vm._v("LATIHAN")])]),_c('p',{staticClass:"text-muted text-sm"},[_c('b',[_vm._v("Rekap hasil latihan soal")]),_vm._v(" di LBB Taruna Cendekia ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body pt-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h2',{staticClass:"lead"},[_c('b',[_vm._v("TRY OUT")])]),_c('p',{staticClass:"text-muted text-sm"},[_c('b',[_vm._v("Rekap hasil try out")]),_vm._v(" di LBB Taruna Cendekia ")])])])])
}]

export { render, staticRenderFns }