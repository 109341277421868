<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                <h4>Pilih salah satu</h4>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-6 d-flex align-items-stretch flex-column">
                    <div class="card bg-light d-flex flex-fill">
                        <div class="card-header text-muted border-bottom-0">
                        
                        </div>
                        <div class="card-body pt-0">
                        <div class="row">
                            <div class="col-12">
                            <h2 class="lead"><b>LATIHAN</b></h2>
                            <p class="text-muted text-sm"><b>Rekap hasil latihan soal</b> di LBB Taruna Cendekia </p> 
                            </div>
                        </div>
                        </div>
                        <div class="card-footer">
                        <div class="text-right">
                            <router-link :to="{
                                name: 'Rapor Evaluasi',
                                params: { filter: { ujian_type: 'latihan'}},
                            }" class="btn btn-sm btn-primary">
                            <i class="fas fa-arrow-right"></i> Detail
                            </router-link>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-6 d-flex align-items-stretch flex-column">
                    <div class="card bg-light d-flex flex-fill">
                        <div class="card-header text-muted border-bottom-0">
                        
                        </div>
                        <div class="card-body pt-0">
                        <div class="row">
                            <div class="col-12">
                            <h2 class="lead"><b>TRY OUT</b></h2>
                            <p class="text-muted text-sm"><b>Rekap hasil try out</b> di LBB Taruna Cendekia </p> 
                            </div>
                        </div>
                        </div>
                        <div class="card-footer">
                        <div class="text-right">
                            <router-link :to="{
                                name: 'Tipe Rapor Evaluasi',
                                params: { filter: { ujian_type: 'ujian'}},
                            }" class="btn btn-sm btn-primary">
                            <i class="fas fa-arrow-right"></i> Detail
                            </router-link>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </section>
</template>
<script>
import { auth, authFetch } from "@/libs/hxcore";
export default {
    name: 'JenisRapor',
    data: () => ({
        ujian: {},
        info: []
    }),
    created: function () {
        // this.loadUjian();
        var info = auth.user();
        if(info)this.info = info;
    },
    methods: {
        // loadUjian(){
        //     let admin = this.info.role_id == '1' ? '/1' : '';
        //     authFetch("/siswa/ujian" + admin).then((res) => {
        //         res.json().then((json) => {
        //         if (json.success) {
        //             this.ujian = json.data;
        //         }
        //         });
        //     });
        // }
    },
    mounted(){
        // this.loadUjian();
    },
}
</script>
<style type="text/css" scoped>
</style>